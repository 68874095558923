import React from "react"
import Navbar from "../components/navbar"
import SEO from "../components/SEO"
import Image from "../components/image"

export default function About() {
    return (
      <main>
        <SEO title="About" pathname="/about" />

        <Navbar></Navbar>

        <div className="lg:max-w-screen-md mx-auto p-6 lg:pt-32 pt-24">
          <h1 className="lg:text-center tracking-tighter">
            <span className="text-pink">/</span>about
          </h1>
          <p className="text-xl">
            Since you piqued interest in my life, here's everything you might
            want to know about me. <i>Spoiler alert</i>, my life is really not
            that interesting. If I bore you to death, I'm not paying for your
            funeral.
          </p>

          <div className="sm:px-40 py-12">
            <Image imgName="ma-face.png" className="rounded-full" />
          </div>

          <p className="text-3xl tracking-tighter font-bold leading-tight mb-10">
            I have spent the majority of my life sitting in front of my
            computer, writing code, and building stuff. Rest of the time I
            jaywalk looking for problems to solve or <i>create them</i>. I
            believe that innovation can happen pretty much anywhere. But, I’m a
            bit biased towards solving problems with technology.
          </p>

          <p className="text-xl mb-10">
            <strong>I made my first website when I was 11.</strong> It was a
            community forum website built using PunBB. I worked at
            Freelancer.com, oDesk (now UpWork), and Themeforest as a freelancer
            or an independent website theme producer. I made about USD$ 16,000+
            over the course of four years. By then, I was 16 years old.
          </p>

          <p className="text-xl mb-10">
            I have a very supportive family who has always encouraged me to
            pursue my dreams. Being the youngest member of the family, I get
            literally all the adoration.{" "}
            <strong>I basically live like a king in my home.</strong>
          </p>

          <p className="text-xl mb-10">
            I grew up and live in Dhaka, Bangladesh. {" "} 
            <strong>
              Growing up I wanted to be a Software Engineer/Computer Scientist.{" "}
            </strong>
            But then I decided to start living dangerously. So, I put
            entrepreneurship as my primary life goal.
          </p>

          <p className="text-xl mb-10">
            <strong>
              I’m pursuing my undergrad on{" "}
              <a
                href="https://www.du.ac.bd/academic/department_item/OSL"
                rel="noreferrer"
                target="_blank"
              >
                Organization Strategy and Leadership
              </a>{" "}
              at the University of Dhaka.{" "}
            </strong>{" "}
            Prior to that, I completed my elementary and high school from{" "}
            <a
              href="https://en.wikipedia.org/wiki/Government_Laboratory_High_School"
              rel="noreferrer"
              target="_blank"
            >
              Government Laboratory High School
            </a>{" "}
            and{" "}
            <a
              href="https://en.wikipedia.org/wiki/Dhaka_College"
              rel="noreferrer"
              target="_blank"
            >
              Dhaka College
            </a>
            .
          </p>

          <p className="text-xl mb-10">
            I’m a fan of simple things.{" "}
            <strong>I don’t like noises and complications.</strong> I usually
            just slam the door or run away when things get too complicated to
            control.
          </p>

          <p className="text-xl mb-10">
            <strong>
              My first and only job was at{" "}
              <a
                href="https://www.joomshaper.com/"
                rel="noreferrer"
                target="_blank"
              >
                JoomShaper
              </a>
              .
            </strong>{" "}
            I joined JoomShaper as an Extension Developer in 2013 and worked
            there for a year. In fact, I was the very first employee of
            JoomShaper.
          </p>

          <p className="text-xl mb-10">
            Late 2014 to mid-2016 was the most transitional phase of my mind. I
            highly invested myself in exploring new concepts and broadening my
            knowledge. Most importantly,{" "}
            <strong>I started to care about how the world works.</strong>
          </p>

          <p className="text-xl mb-10">
            <strong>
              In early 2017, I co-founded{" "}
              <a href="https://symbl.co/" rel="noreferrer" target="_blank">
                Symbl
              </a>{" "}
              with{" "}
              <a
                href="https://www.linkedin.com/in/akkhor/"
                rel="noreferrer"
                target="_blank"
              >
                @Atif
              </a>
              .
            </strong>{" "}
            It is a bootstrapped technology startup that makes scalable digital
            products to drive innovations. I currently run Symbl as the Chief
            Executive Officer.
          </p>

          <p className="text-3xl tracking-tighter font-bold leading-tight mb-10">
            Right now, my passion lies upon building scalable digital solutions
            empowered with extensive UX engineering to execute real-life values.
            I take that passion to work while working with my amazing team at
            Symbl.
          </p>

          <p className="text-xl mb-10">
            I worked as an Instructor at{" "}
            <a href="http://spade.academy/" rel="noreferrer" target="_blank">
              Spade Academy
            </a>{" "}
            for about a year undertaking courses on Responsive Web Design. Currently,{" "}
            <strong>
              I make premium online courses for{" "}
              <a
                href="http://10minuteschool.com/"
                rel="noreferrer"
                target="_blank"
              >
                10 Minute School
              </a>
            </strong>
            . I am looking forward to more opportunities in expanding my trainer
            career.
          </p>

          <p className="text-xl mb-10">
            <strong>
              <a
                href="https://www.imdb.com/title/tt0338013/"
                rel="noreferrer"
                target="_blank"
              >
                Eternal Sunshine of the Spotless Mind
              </a>{" "}
              is my most favorite film of all time
            </strong>
            . I don’t befriend someone who hasn’t seen this movie.{" "}
            <i>You must watch it if you haven’t yet!</i>
          </p>

          <p className="text-xl mb-10">
            I’m a{" "}
            <a href="https://www.arsenal.com/" rel="noreferrer" target="_blank">
              Gooner
            </a>
            . I love progressive and indie music. I adore both cattos and
            doggos. I enjoy coming-of-age novels.{" "}
            <strong>
              <a
                href="https://en.wikipedia.org/wiki/Biryani#Kacchi_biryani"
                rel="noreferrer"
                target="_blank"
              >
                Kacchi Biriyani
              </a>{" "}
              is my favorite meal
            </strong>
            , while{" "}
            <a
              href="https://en.wikipedia.org/wiki/Ras_malai"
              rel="noreferrer"
              target="_blank"
            >
              Rossomalai
            </a>{" "}
            being the favorite dessert.
          </p>

          <p className="text-xl mb-10">
            I’m not much of a traveler. In fact,{" "}
            <strong>I’m a very homesick person</strong>. I require babysitting.
            But I wish to travel around the world someday. And when I do, it
            must be with my friends. They are the reason I never had to/have to
            look back or step backward.
          </p>

          <p className="text-3xl tracking-tighter font-bold leading-tight mb-20">
            I’m curious to find out what lies ahead in my life and how they will
            shape my story. But I am confident of never settling.
          </p>
        </div>
      </main>
    )
}